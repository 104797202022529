import gql from 'graphql-tag'

export default gql`
  query NextSevenDaysSummary(
    $company: ID!
    $date: Date!
    $interval: Interval!
    $employmentsQuery: QueryEmploymentInput
  ) {
    companySchedule(
      company: $company
      date: $date
      interval: $interval
      employmentsQuery: $employmentsQuery
    ) {
      holidays {
        date
        name
        location
        type
      }
    }
  }
`
